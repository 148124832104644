import React from 'react'
import styled from 'styled-components'
import {Input} from '@geist-ui/react'

export default function App() {
  const [value, setValue] = React.useState("")
  const handler = (e: any) => {
    setValue(e.target.value)
    console.log(e.target.value)
  }
  
  return (
    <div style={{margin: 30}}>
      <Header>HSE Barcode Generator</Header>
      <div>
        <Input value={value} onChange={handler} placeholder="Last 4 Digits of 900000" />
        <p>barcode will generate below once 4 digits are typed.</p>
        <br />
        {value.length === 4 && <img style={{marginTop: 50}} alt="barcode" src={`https://www.webarcode.com/barcode/image.php?code=90000${value}&type=C39&xres=1&height=40&width=250&font=3&output=png&style=68`} />}
      </div>
    </div>
  )
}

const Header = styled.h2`
  font-weight: 700;
  background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`