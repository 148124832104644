import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { GeistProvider, CssBaseline } from '@geist-ui/react'
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const theme = {
  colors: {
    primary: "#0070f3",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
      <Providers>
        <CssBaseline />
        <App />
      </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

function Providers(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <GeistProvider>
        {props.children}
      </GeistProvider>
    </ThemeProvider>
  )
}